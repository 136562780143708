import { FC } from "react";
import { GameStatus } from "../utils/types";
import GameStatusEmoticon from "./GameStatusEmoticon";
import SocialLinks from "./SocialLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

type Props = {
  gameStatus: GameStatus;
  restartTheGame: () => void;
};

const Greetings: FC<Props> = (props) => {
  const { gameStatus, restartTheGame } = props;
  return (
    <div className="greetings fade-in">
      <GameStatusEmoticon gameStatus={gameStatus} />
      <button
        onClick={() => restartTheGame()}
        className="btn btn-lg btn-block fade-in"
      >
        Try again!
      </button>
      <p>
        What you have played right now is a simple memorizing game, based on{" "}
        <a href="https://youtu.be/ktkjUjcZid0" target="_blank" rel="noreferrer">
          youtube original series, Mindfield - S03E01 - The Cognitive Tradeoff
          Hypothesis
        </a>{" "}
        You can find the source code of this website on{" "}
        <a
          href="https://github.com/mhdmhsni/Ai"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faGithub} size="1x" />
        </a>{" "}
      </p>
      <SocialLinks />
    </div>
  );
};

export default Greetings;
