import React from "react";
import ReactDOM from "react-dom/client";
import "./css/global.css";
import "./scss/style.scss";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";

// App Route components
import Root from "./routes/Root";
import initiateGlobalCssVariables from "./utils/css-variables";
import NotFound from "./routes/NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

initiateGlobalCssVariables();

// remover <StrictMode></StrictMode> because useEffect was being called twice (due to mounting, dismounting)
root.render(<RouterProvider router={router}></RouterProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
